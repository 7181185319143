import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

import { graphql } from "gatsby";

import "../css/app.css";
import {getViewModule} from "../util/router";

const LangSelPage = ({ data, ...props }) => {
    const { allStrapiEntities: {nodes: rootContainers }, allStrapiAppSettings, allStrapiI18NLocale: { nodes: locales} } = data;

    const settings = allStrapiAppSettings?.nodes[0];

    const strapiChildren = _.sortBy(rootContainers, e => locales.findIndex(l => l.code === e.locale))

    strapiChildren.forEach( e => {
        const locale = locales.find(l => l.code === e.locale);
        e.title = locale.name;
    });



    const entity = {
        strapiChildren,
        largeImage: settings?.logo || null,
        Components: [],
        Roles: []
    };

    const selectedModule = settings?.defaultModule?.name || 'material';

    const viewProps = {
        entity,
        selectedModule,
        config: {},
        showBackbutton: false,
        fullscreenMode: false

    }

    const ViewModule = getViewModule(selectedModule);

    useEffect(() => {
        if (typeof window !== 'undefined' && settings?.startpage !== 'languageSelection')
            window.location.replace('/entity/root.html');

    }, [settings]);

    return settings?.startpage === 'languageSelection' && <ViewModule  {...props} {...viewProps} strapiChildren={strapiChildren} />;

};

export default LangSelPage;

export const query = graphql`
    query {
        allStrapiEntities(filter: {identifier: {eq: "root"}}) {
            nodes {
              id
              identifier
              locale
              title
              smallImage {
                  localFile {
                      childImageSharp {
                          gatsbyImageData(width: 500)
                      }
                  }
               }
               Roles
            }
              
        }
        allStrapiAppSettings {
            nodes {

                logo {
                    localFile {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
                startpage
                defaultModule {
                    name
                }
            }
        }
        allStrapiI18NLocale(sort: {fields: id, order: ASC}) {
            nodes {
                name
                code
                isDefault
            }
        }
    }
`;

